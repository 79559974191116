.round3StartOrComplete {
  margin-left: 5vw;
  font-family: "Poppins";
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  background: #f5f5f5;
  padding: 20px 30px;
  max-width: 800px;
  margin-top: 50px;
  .status-main {
    display: flex;
    justify-content: space-between;
    .text-1 {
      border-radius: 3px;
      background: #ccdeff;
      padding: 7px 16px;
      width: fit-content;
      font-weight: 600;
    }
    .text-2 {
      color: #4f4f4f;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 16px;
    }
  }

  .schedule {
    .text-2 {
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 12px;
    }
    .text-3 {
      color: #222;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 152%; /* 21.28px */
      margin-top: 14px;
    }
    .failedRound {
      color: #f47501;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 12px;
      & > span {
        font-weight: 500;
      }
    }
    & > button {
      border-radius: 8px;
      background: #0B52D4;
      border: none;
      outline: none;
      padding: 13px 24px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      margin-top: 20px;
      cursor: pointer;
    }
  }
  .completed {
    color: rgb(45, 151, 45);
    margin-top: 12px;
    font-weight: 600;
    font-size: 1rem;
  }
  .meetDetails {
    display: flex;
    justify-content: space-between;
    .side-buttons {
      & > button {
        width: 183px;
        padding: 13px 0;
        display: block;
        outline: none;
        border: none;
        font-family: "Poppins";
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        border-radius: 8px;
        line-height: 140%; /* 22.4px */
      }
      .Reschedule {
        border: 1px solid #0B52D4;
        margin-bottom: 18px;
        background: inherit;
        color: #0B52D4;
      }
      .join {
        background: #0B52D4;
        color: #fff;
        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
    .non-buttons {
      .text-2 {
        color: #4f4f4f;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 16px;
      }
      .text-3 {
        color: #222;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 152%; /* 21.28px */
        margin-top: 8px;
      }
      .items-main {
        margin-top: 28px;
        display: flex;
        gap: 32px;
        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          & > div:nth-child(2) {
            color: #7b7777;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.scheduleMeeting {
  position: relative;
  padding: 30px;
  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }
  .text-1 {
    color: #000;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
  }
  .calendar-wrapper {
    display: flex;
    gap: 100px;

    .left {
      .calendar-main {
        .react-calendar {
          // border: 1px solid #006edc;
        }
      }
    }

    .left,
    .right {
      .icons-main {
        display: flex;
        gap: 10px;
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-items: center;
        margin-bottom: 32px;
        margin-top: 20px;
        & > div:nth-child(2) {
          margin-bottom: 5px;
        }
      }
    }
    .right {
      min-width: 300px;
      .slots-main {
        .loaderMain {
          font-size: 16px;
          font-family: "Poppins";
        }
        .gridContainer {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          max-height: 270px;
          height: fit-content;
          overflow-y: scroll;
          padding-right: 10px;
          /* Three columns with equal width */
          grid-gap: 15px;
          /* Gap between grid items */
          .gridItem {
            background-color: #fff;
            padding: 15px;
            text-align: center;
            border: 1px solid #cac7c7aa;
            border-radius: 4px;
            height: 60px;
          }
          .gridItem:hover {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.52);
          }
          .selectedItem {
            background: #006edc;
            color: #fff;
          }
        }

        .info-item {
          margin-top: 20px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid #e1e1e1;
          background: #e5efff;
          padding: 4px 6px;
          color: #6a6868;
          font-family: "Poppins";
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 145.455% */
          .icon {
            color: #0b52d4;
            background-color: inherit;
            & > svg {
              height: 16px;
              width: 16px;
            }
            // margin-bottom: 10px;
          }
          .text2 {
            color: #6a6868;
            font-family: "Poppins";
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
      }
    }
  }
  .bookslot {
    text-align: center;
    & > button {
      margin-top: 36px;
      border: none;
      outline: none;
      border-radius: 8px;
      background: #0B52D4;
      padding: 13px 24px;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      cursor: pointer;
    }
  }
}

.successMetting {
  position: relative;
  padding: 30px;
  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }
  .icon-1 {
    text-align: center;
    margin-top: 10px;
  }
  .text-1 {
    margin-top: 16px;
    text-align: center;
    color: #2f2f2f;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .text-2 {
    margin-top: 12px;
    text-align: center;

    color: #2f2f2f;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .button-1 {
    text-align: center;
    & > button {
      margin-top: 36px;
      border: none;
      outline: none;
      border-radius: 8px;
      background: #0B52D4;
      padding: 13px 24px;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      cursor: pointer;
    }
  }
}

.resume-main-r3 {
  width: 476px;
  position: relative;
  top: 0;
  .close {
    position: absolute;
    top: -20px;
    right: 20px;
    cursor: pointer;
  }
  .Jdmain {
    margin: 0 auto;
    margin-top: 40px;
    width: calc(100% - 60px);

    .resume {
      color: #4a4a4a;
      text-align: center;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .dropBox {
      margin-top: 20px;
      height: 143px;
      border-radius: 8px;
      border: 1px dashed #e0e0e0;
      background: #fff;
      box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);
      display: flex;
      flex-direction: column;
      align-items: center;

      .uploadIcon {
        margin-top: 28px;
        cursor: pointer;
        & > img {
          width: 24px;
          height: 24px;
        }
      }
      .firstLabel {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 16px;
        & > label {
          & > span {
            color: #0b52d4;
            font-family: "Poppins";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
          }
        }
      }
      .secondLabel {
        color: #afafaf;
        font-family: "Poppins";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 8px;
      }
    }
    .selected {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      height: fit-content;
      align-items: center;
      background: #fff;
      width: fit-content;
      margin-top: 14px;
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      justify-content: flex-start;
      border-radius: 4px;
      background: #fff;
      box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.1),
        -2px -2px 4px 0px rgba(33, 33, 33, 0.05);
      & > div {
        padding: 5px 10px;
        & > img {
          cursor: pointer;
        }
      }

      .multiple {
        display: flex;
        gap: 15px;
        & > div {
          & > img {
            cursor: pointer;
          }
        }
      }
    }
    .selected-certificates {
      background: inherit;
      & > div {
        background: #fff;
        padding: 13px 12px;
      }
    }
    .last-button {
      text-align: center;
      & > button {
        border-radius: 8px;
        background: #0B52D4;
        padding: 12px 20px;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        border: none;
        outline: none;
        line-height: normal;
        margin: 0 auto;
        gap: 8px;
        margin-top: 16px;
        margin-bottom: 16px;
        display: flex;
        height: 48px;
        align-items: center;
        & > span {
          margin-top: 7px;
        }
      }
    }
    .upload-error {
      color: #e5261c;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 8px;
      & > span:nth-last-child(1) {
        color: #e5261c;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
