.raiseTicketMain {
  position: relative;
  padding: 30px;
  font-family: "Poppins";

  .closeBox {
    position: absolute;
    right: 25px;
    top: 20px;
    width: fit-content;
    height: fit-content;

    img {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }
  }

  .title {
    color: #333;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 33.6px */
    margin-top: 10px;
  }

  .divider {
    border-bottom: 1px solid #cacaca;
    margin-top: 24px;
  }

  .myClassName {
    width: fit-content;
    min-width: 200px;
    border-radius: 6px;
    border: 1px solid #000;
    margin-top: 32px;
    display: flex;
    align-items: center;

    &>div {
      border: none;
      width: 100%;
      border: 1px solid #000;
      box-sizing: border-box;
      border-radius: 6px;
    }

    &>div:nth-child(1) {
      border: none;
    }
  }

  .text {
    &>textarea {
      border-radius: 4px;
      border: 1px solid #cfcdcd;
      background: #fff;
      width: calc(100% - 30px);
      resize: none;
      min-height: 140px;
      margin-top: 20px;
      font-size: 1rem;
      padding: 12px 14px;
      outline: none;
      font-family: inherit;
    }

    &>textarea::placeholder {
      font-family: inherit;
    }
  }

  .msg {
    color: #7b7777;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    margin-top: 12px;
  }

  .post {
    &>button {
      border-radius: 8px;
      background: #0b52d4;
      //   width: 100%;
      width: fit-content;
      color: var(--white-high, #fff);
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Poppins";
      /* font/button/large/sentence */
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 171.429% */
      padding: 13px 36px;
      margin-top: 32px;
      border: none;
      outline: none;
      //   cursor: not-allowed;
    }
  }
}