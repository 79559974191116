.WhatweDoNew {
  width: 100%;
  margin-top: 80px;

  .heading {
    color: #212121;
    font-family: "Poppins";
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    text-align: center;
  }

  .desc {
    color: #313131;
    font-family: "Poppins";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    /* 180% */
    width: calc(100% - 14%);
    margin: 0 auto;
    margin-top: 24px;
    position: relative;
    text-align: center;
    z-index: 11;

    &>span {
      font-weight: 500;
    }
  }

  .read-more {
    display: none;
  }

  .content-container {
    position: relative;
    padding-top: 70px;
    margin-top: -15px;

    .background-image-div {
      position: absolute;
      width: 50%;
      /* One third of the container */
      height: 105%;
      /* Full height of the container */
      top: 0px;
      z-index: 10;
      background-repeat: no-repeat;
      background-size: contain;
      /* Fit the image without cropping */
    }

    .details {
      position: relative;
      z-index: 11;
      display: flex;
      width: calc(100% - 14%);
      margin: 0 auto;
      //   gap: 30px;
      border-radius: 30px;
      background: #fff;
      box-shadow: -3px -3px 4px 0px rgba(79, 79, 79, 0.14),
        3px 3px 4px 0px rgba(79, 79, 79, 0.14);
      overflow: hidden;

      //   margin-top: 60px;
      .desc {
        color: #313131;
        font-family: "Poppins";
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        /* 180% */
        padding: 40px 30px;
        padding-right: 0px;
        text-align: left;
        margin-top: 0px;

        &>span {
          font-weight: 500;
        }
      }

      .illustration {
        padding: 40px 13px;
        padding-left: 0px;

        &>img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .right-filler {
      position: absolute;
      right: 25px;
      top: 45%;
    }

    .right-filler-mobile {
      display: none;
    }
  }
}

@media (max-width: 650px) {
  .WhatweDoNew {
    margin-top: 30px;

    .heading {
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.2px;
    }

    .desc {
      font-size: 14px;
      width: calc(100% - 48px);
      line-height: normal;
    }

    .content-container {
      padding-top: 40px;

      .background-image-div {
        position: absolute;
        width: 60%;
        /* One third of the container */
        height: 50%;
        /* Full height of the container */
        max-height: 317.714px;
      }

      .details {
        flex-direction: column;
        width: calc(100% - 40px);
        border-radius: 22px;
        background: #fff;
        box-shadow: -3px -3px 4px 0px rgba(79, 79, 79, 0.14),
          3px 3px 4px 0px rgba(79, 79, 79, 0.14);

        .desc {
          font-size: 14px;
          padding: 16px 4px;
          padding-bottom: 0px;
          width: 95%;
          margin: 0 auto;
          margin-top: 0px;
          text-align: center;

          line-height: 2em;
          height: 6em;
          /* for three lines */
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          /* optional */
          transition: height 0.3s ease;
          /* optional, for smooth height transition */

          &>span {
            font-weight: 500;
          }
        }

        .read-more {
          display: block;
          width: fit-content;
          margin: 0 auto;
          color: #0b52d4;
          text-align: center;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.2px;
          text-transform: capitalize;
          margin-top: 9px;

          &>div {
            &>img {
              transform: rotate(270deg);
              width: 20px;
              height: 20px;
            }
          }
        }

        .illustration {
          padding: 10px 0px;
          padding-left: 0px;
          padding-top: 16px;

          &>img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .right-filler {
        display: none;
      }

      .right-filler-mobile {
        position: absolute;
        // right: ;
        // top: 45%;
        bottom: 10%;
        top: auto;
        right: 0px;
        display: block;
      }
    }
  }
}