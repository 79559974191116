// src/BlogCardsContainer.scss
.blogs-main {
  max-width: 92%;
  margin: 0 auto;
  margin-top: 80px;

  @media (max-width: 480px) {
    margin-top: 32px;
  }

  .title {
    color: var(--systemGrey-900, #212121);
    font-family: "Poppins";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;

    letter-spacing: 0.2px;
    // text-transform: capitalize;
    text-align: center;

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  .blog-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-top: 50px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 28px;
      margin-top: 30px;
    }

    // src/BlogCard.scss
    .blog-card {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      max-width: 100%;
      display: flex;
      flex-direction: column;

      .blog-card-image {
        width: 100%;
        height: 200px;
        position: relative;
        /* Ensure it's positioned properly */
        overflow: hidden;
        /* Hide overflow */

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* Maintain the aspect ratio, cover the container */
          object-position: center;
          /* Center the image */
          display: block;
          /* Ensure there's no extra space from inline elements */
        }
      }


      .blog-card-content {
        padding: 20px;
        background-color: #0b52d4;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          margin: 0px;
          padding: 0px;
          color: #fff;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 37px;
          /* 231.25% */
          text-align: left;

          @media (max-width: 480px) {
            font-size: 16px;
          }
        }

        .description {
          margin: 0px;
          margin-bottom: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          color: #fff;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media (max-width: 480px) {
            font-size: 14px;
          }
        }

        .blog-card-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .date-read-time {
            display: flex;
            align-items: center;

            .date {
              color: #fff;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .dot {
              width: 4px;
              height: 4px;
              background-color: #cccccc;
              border-radius: 50%;
              margin: 0 8px;
            }

            .read-time {
              color: #fff;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .read-more {
            display: flex;
            align-items: "center";
            gap: 5px;
            text-decoration: none;
            color: #fff;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @media (max-width: 480px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    // Media queries for responsiveness
    @media (max-width: 768px) {
      .blog-card {
        max-width: 100%;
        margin: 10px;
      }
    }

    // Media queries for responsiveness
    @media (max-width: 768px) {
      .blog-card {
        max-width: 100%;
        margin: 10px;
      }
    }
  }
}