.startedRound1Container {
  font-family: "Poppins";
  .timeAndLang {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    .avgTime {
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      & > span {
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }
    }
    .languageWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      .dropDown-wrapper {
        min-width: 200px;
      }
    }
  }
  .split-container {
    .right-container {
      user-select: none; /* Standard syntax */
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* Internet Explorer/Edge */
      -moz-user-select: none; /* Firefox */
      .right-wrapper {
        margin-right: 40px;
        .questionAndDetails {
          max-height: 366px;
          border-radius: 10px;
          overflow-y: auto;
          padding: 24px 20px;
          background: rgba(204, 222, 255, 0.32);
          .question-no {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            & > div:nth-child(1) {
              display: flex;
              gap: 10px;
              color: #212121;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            }
          }
          .question {
            color: #4f4f4f;
            font-family: "Poppins";
            margin-top: 14px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            .examples {
              display: flex;
              flex-wrap: wrap;
              gap: 12px;
            }
            border-bottom: 1px solid #e0e0e0;
            padding-bottom: 16px;
          }
          & > div:nth-last-child(1) {
            border-bottom: none;
          }
          .description {
            color: #212121;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
            margin-top: 14px;
          }
        }
        .test-cases-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 18px;
          .testCase {
            color: #4f4f4f;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
          }
          .all-test-cases {
            border-radius: 6px;
            background: #4f4f4f;
            color: #fff;
            font-family: "Poppins";
            padding: 6px 20px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            cursor: pointer;
          }
        }
        .textcase-wrapper {
          margin-top: 12px;
          padding: 20px;
          border-radius: 10px;
          background: rgba(204, 222, 255, 0.32);
          margin-bottom: 50px;
          .each-testcase {
            width: 100%;
            display: flex;
            gap: 50px;
            color: #4f4f4f;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            .active {
              color: #0b52d4;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 19.6px */
              border-bottom: 2px solid #0b52d4;
            }
          }
          .inputAndOutput {
            color: #4f4f4f;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 18.2px */
            margin-top: 14px;
            display: flex;
            align-items: center;
            gap: 16px;
            & > span {
              flex: 1;
              border-radius: 6px;
              border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
              padding: 14px 16px;
              color: #bdbdbd;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            .output {
              color: #212121;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
          .runCode {
            text-align: right;
            & > button {
              border-radius: 6px;
              background: #0B52D4;
              cursor: pointer;
              border: none;
              outline: none;
              padding: 8px 16px;
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 19.6px */
              margin-top: 16px;
              font-family: "Poppins";
            }
          }
        }
      }
    }
    .right-containerr::-webkit-scrollbar {
      display: none !important;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .right-container {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      scrollbar-width: "none"; /* For Firefox */
    }
    .left-container {
      .left-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .editorAndConsole {
          height: 60vh;
          overflow-y: auto;
          overflow-x: hidden;
          .editorContainer {
            height: 60vh;
            border-radius: 8px;
            font-size: 16px;
            background: #fff;
            box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.1),
              -2px -2px 4px 0px rgba(79, 79, 79, 0.1);
            .ace_editor {
              font-size: 16px !important;
            }
            & > textarea {
              border: none;
              outline: none;
              border-radius: 8px;
              background: inherit;
              padding: 16px;
              font-size: 16px;
              line-height: 22px;
              font-family: "Poppins";
            }
          }
          .consoleOutput {
            margin-top: 20px;
            & > div:nth-child(1) {
              color: #4f4f4f;
              font-family: "Poppins";
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 18.2px */
            }
            & > div:nth-child(2) {
              min-height: 187px;
              padding: 16px;
              border-radius: 8px;
              margin-top: 8px;
              font-weight: 500;
              color: #4f4f4f;
              margin-bottom: 20px;
              border: 0.6px solid #4318ff;
              background: #fff;
              box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.1),
                -2px -2px 4px 0px rgba(79, 79, 79, 0.1);

              .response {
                color: #212121;
              }
              & > div {
                display: flex;
                gap: 10px;
                margin: 5px 0;
                & > span:nth-child(1) {
                }
              }
            }
            .loader-window {
              text-align: center;
              width: 100%;
              & > div {
                margin: 0 auto !important;
                width: fit-content;
                text-align: center;
                margin-top: 50px !important;
              }
            }
          }
        }
        .bottomButtons {
          margin-bottom: 100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .submit {
            display: flex;
            gap: 20px;
            align-items: center;
            .submitButton {
              & > button {
                border-radius: 8px;
                background: #0bb762;
                padding: 13px 20px;
                color: #fff;
                font-family: "Poppins";
                border: none;
                outline: none;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 22.4px */
              }
            }
            .reset {
              display: flex;
              cursor: pointer;
              gap: 5px;
              align-items: center;
              color: #4f4f4f;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
              & > div:nth-child(2) {
                // margin-top: 2px;
              }
            }
          }
          .nextQuestion {
            & > button {
              border-radius: 8px;
              background: #0B52D4;
              color: #fff;
              font-family: "Poppins";
              padding: 13px 20px;
              border: none;
              outline: none;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 22.4px */
            }
          }
        }
      }
    }
  }
}

.resetMain {
  position: relative;
  width: 400px;
  padding: 20px;
  .close {
    position: absolute;
    // top: -10px;
    right: 20px;
  }
  .title1 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-top: 10px;
    text-align: center;
  }
  .title2 {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 10px;
    text-align: center;
  }
  .last {
    display: flex;
    gap: 36px;
    justify-content: center;
    margin-top: 21px;
    & > button {
      border: none;
      outline: none;
      cursor: pointer;
      background: inherit;
      border-radius: 8px;
    }
    & > button:nth-child(1) {
      border: 1px solid #777777;
      padding: 12px 24px;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #777777;
    }
    & > button:nth-child(2) {
      background: #0B52D4;
      padding: 13px 24px;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #fff;
    }
  }
}
.all-cases-wrapper {
  width: 380px;
  position: relative;
  text-align: center;
  padding: 22px 0;
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .meter {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    .clock {
      // border: 1px solid blue;
      width: 50px;
      height: 39.239px;
      padding: 0px;
      box-sizing: border-box;
      margin: 0;
      overflow: hidden;
      & > img {
        height: 100%;
      }
    }
    .needle {
      position: absolute;
      bottom: 5px;
      // border: 1px solid red;
      margin: 0 auto;
      left: 23%;
      width: 14.153px;
      height: 13.482px;
      box-sizing: border-box;
      transform-origin: right center;
      bottom: 5px;
      & > img {
        width: 100%;
        height: 100%;
        margin-right: -10px;
        // border: 1px solid;
      }
    }
  }
  .text {
    margin-top: 18px;
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .okay {
    & > button {
      border: none;
      outline: none;
      border-radius: 8px;
      background: #0B52D4;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px 24px;
      margin-top: 24px;
    }
  }
}
