// sliderStyles.scss

.slick-dots {
  bottom: -40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  li {
    margin: 0 10px !important;

    button {
      width: 12px !important;
      height: 12px !important;
      border-radius: 50% !important;
      background-color: #6d96e1 !important;
      border: none !important;

      &:before {
        display: none !important;
      }
    }

    &.slick-active button {
      width: 24px !important;
      height: 12px !important;
      background-color: #062B6E !important;
      border-radius: 12px !important;
      margin: 0px;
    }
  }
}

.slick-list {
  padding: 0;
  overflow: hidden;
}

.slick-slide {
  padding: 0 10px;
  box-sizing: border-box;
  height: 100% !important;

  &>div {
    height: 100% !important;
  }
}

.slick-track {
  display: flex !important;
}

.slick-arrow {
  display: none !important;
}