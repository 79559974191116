.metting-diaclaimer {
  display: flex;
  align-items: center;
  color: #0b52d4;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 19.6px */
  gap: 8px;
  margin-top: 13px;
}