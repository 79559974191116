.candidate-offers {
  margin-left: 5vw;
  margin-top: 40px;

  .title1 {
    margin-top: 30px;
    color: #212121;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .desc1 {
    margin-top: 12px;
    color: #8d8d8d;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .offers-link {
    display: flex;
  }
}

.alloffers {
  margin-top: 30px 0;
  margin-left: 5vw;
  font-family: "Poppins";

  .jobContainer {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background: #fff;
    max-width: 750px;
    padding: 12px 16px;
    margin: 16px 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 20px;
    }

    .leftSide,
    .rightSide {
      color: #999;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      /* 28px */
      &>div {
        margin: 14px 0;
      }

      span {
        color: #4f4f4f;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }

      .status {
        display: flex;
        gap: 8px;

        &>span {
          width: fit-content !important;
          // color: #34a853;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          /* 28px */
        }
      }

      .details {
        display: flex;
        gap: 25px;
      }

      .apply {
        margin: 0px;

        text-align: right;

        @media (max-width: 600px) {
          text-align: left;
        }

        button {
          min-width: 126px;
          border-radius: 8px;
          border: none;
          // background: var(--black-high, #212121);
          background: #0b52d4;
          padding: 13px 20px;
          color: var(--white-high, #fff);
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          /* 171.429% */
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }
      }

      .typeOfWork {
        display: flex;
        gap: 16px;
        align-items: center;

        &>span:nth-child(1) {
          border-radius: 3px;
          border: 1px solid rgba(67, 24, 255, 0.6);
          background: #ebe6ff;
          color: rgba(67, 24, 255, 0.7);
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          /* 114.286% */
          letter-spacing: 0.56px;
          padding: 7px 16px;
          border-radius: 3px;
          border: 1px solid rgba(6, 43, 110, 0.6);
          background: #ccdeff;
          color: #062b6e;
          text-transform: uppercase;
        }

        &>span:nth-child(2) {
          color: #7b7777;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* 22.4px */
          text-transform: uppercase;
        }
      }

      .viewApplication {
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;

        &>span {
          color: #062b6e;
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          /* 171.429% */
        }

        &>span:nth-child(2) {
          margin-top: 8px;
        }
      }

      .refer-and-view {
        display: flex;
        gap: 24px;

        .refer {
          cursor: pointer;
          border-radius: 5px;
          border: 1px solid #e5b50b;
          background: #fff;
          color: #e5b50b;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 10px 12px;
        }
      }
    }

    .rightSide {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: center;
    }
  }

  .jobContainerExpired {
    border-radius: 4px;
    border: 1px solid #96969679;
    background: #fff;
    max-width: 650px;
    padding: 12px 16px;
    margin: 16px 0;
    color: #7b7777;
    font-family: "Inter";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    // opacity: 0.5;

    span {
      color: #7b7777;
      font-family: "Inter";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }

    .typeOfWork {
      display: flex;
      gap: 16px;
      align-items: center;

      &>span:nth-child(1) {
        border-radius: 3px;
        border: 1px solid rgba(67, 24, 255, 0.6);
        background: #ebe6ff;
        color: rgba(67, 24, 255, 0.7);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 114.286% */
        letter-spacing: 0.56px;
        padding: 7px 16px;
        border-radius: 3px;
        border: 1px solid rgba(6, 43, 110, 0.6);
        background: #ccdeff;
        color: #062b6e;
        text-transform: uppercase;
        opacity: 0.5;
      }

      &>span:nth-child(2) {
        color: #de3a3b;
        text-align: justify;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 22.4px */
        text-transform: uppercase;
      }

      .exp {
        // opacity: 1;
      }
    }

    .pos,
    .details,
    .doj {
      opacity: 0.5;
    }

    .pos,
    .details,
    .doj,
    .company,
    .loc {
      color: #999;
      text-align: justify;
      font-family: "Poppins";
      margin-top: 14px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      &>span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: #4f4f4f;
        font-size: 14px;
      }
    }

    .company,
    .loc {
      margin-top: 0px;
    }

    .details {
      display: flex;
      gap: 30px;
    }
  }
}

button {
  cursor: pointer;
}