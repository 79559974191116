.client-data-form-wrapper {
  $modal-background-color: #fff;
  $modal-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  $button-background-color: #002b5b;
  $button-hover-color: #001f40;
  $button-text-color: #fff;

  .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .modal-content {
    background-color: $modal-background-color;
    border-radius: 10px;
    padding: 30px;
    width: 600px;
    position: relative;
    box-shadow: $modal-box-shadow;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .submit-button {
    grid-column: span 2;
    border-radius: 8px;
    padding: 10px 20px;
    background-color: $button-background-color;
    color: $button-text-color;
  }

  .submit-button:hover {
    background-color: $button-hover-color;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .dropDown-wrapper {
    position: relative;
    width: 100%; /* Adjust based on the parent container */
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(33, 33, 33, 0.15),
      0 4px 8px rgba(33, 33, 33, 0.1);
    border-radius: 8px; /* Optional: Add border-radius for smoother corners */
    height: 56px;
  }

  .value {
    background-color: #fff;
  }
  .placeholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff !important;
    border-radius: 8px; /* Set border-radius like the Material-UI input */
    box-shadow: 0 -2px 10px rgba(33, 33, 33, 0.15),
      0 4px 8px rgba(33, 33, 33, 0.1); /* Apply shadow */
    cursor: pointer;
    height: 56px;
  }

  .options-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 8px; /* Match the border-radius of the placeholder */
    box-shadow: 0 4px 8px rgba(33, 33, 33, 0.1); /* Add shadow to the dropdown */
    z-index: 1000; /* Ensure the dropdown is on top of other elements */
  }

  .options-wrapper div {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    background-color: #fff;
  }

  .options-wrapper div:hover {
    background-color: #f0f0f0;
  }
}
