.instructions-wrapper {
  position: relative;
  padding: 20px;
  font-family: "Poppins";
  max-width: 495px;

  .close {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }

  .text1 {
    color: #7b7777;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 4px;
  }

  .all-desc {
    margin-top: 20px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &>div {
      margin-top: 12px;
      display: flex;
      // max-width: 430px;
      gap: 3px;

      .item {
        &>span {
          color: #7b7777;
          font-weight: 600;
        }
      }
    }
  }

  .okay {
    &>button {
      border-radius: 8px;
      background: #0b52d4;
      border: none;
      outline: none;
      padding: 13px 24px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      /* 22.4px */
      margin-top: 20px;
      cursor: pointer;
    }
  }
}