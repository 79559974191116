.personal-details {
  margin-left: 5vw;

  .title {
    color: #7c7c7c;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 30px;
  }

  .dev-details {
    width: fit-content;
    // border: 1px solid;

    &>form {

      //   border: 1px solid;
      //   margin-top: 24px;
      .items-container {

        // position: relative;
        // z-index: 5;
        .item-container-title {
          color: #212121;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 22.4px */
          margin-top: 30px;
          display: flex;
          flex-wrap: wrap;

          // gap: 48px;
          width: 210px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }

        .item-content {
          margin-top: 28px;
          width: 100%;
          max-width: 1160px;
          border-radius: 8px;
          border: 1px solid rgba(224, 224, 224, 0.4);
          background: #fff;
          box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.04),
            -2px -2px 4px 0px rgba(79, 79, 79, 0.04);
          padding: 30px 26px;

          &>div:nth-child(1) {
            margin-top: 0px;
          }
        }

        .arrow-container {
          &>img {
            filter: drop-shadow(2px 2px 4px rgba(79, 79, 79, 0.04)) drop-shadow(-2px -2px 4px rgba(79, 79, 79, 0.04));
            border-radius: 50%;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(-5px);
          }

          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeOut {
          from {
            opacity: 1;
            transform: translateY(0);
          }

          to {
            opacity: 0;
            transform: translateY(-5px);
          }
        }

        .fade-in {
          // animation: fadeIn 0.3s ease-out forwards;
          animation: fadeIn 0.3s ease-out;
          // z-index: 10;
          // position: relative;
        }

        .fade-out {
          animation: fadeOut 0.3s ease-out forwards;
          // display: none;
        }
      }

      .field1 {
        display: flex;
        flex-wrap: wrap;

        gap: 30px;
        font-family: "Poppins";
        margin-top: 28px;

        .field2,
        .field3 {
          display: flex;
          flex-wrap: wrap;

          flex-direction: column;
          min-width: 260px;

          &>label {
            color: #212121;
            font-family: "Poppins";
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
            margin-bottom: 8px;
          }

          &>input,
          &>div>input {
            outline: none;
            padding: 16px 16px;
            box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.04);
            color: #4f4f4f;
            font-family: "Poppins";
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
            border-radius: 8px;
            border: 1px solid #e0e0e0;
            background: #f8f9fb;
          }

          &>input::placeholder,
          &>div>input::placeholder {
            color: #a7a7a7;
            font-family: "Poppins";
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
          }

          &>input::-webkit-outer-spin-button,
          &>input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          .field3 {
            flex-direction: row;
            gap: 10px;
            min-width: 280px;

            &>div:nth-child(1) {
              max-width: 100px;
            }

            &>div:nth-child(2) {
              flex: 1;

              &>input {
                width: 100%;
              }

              &>input::-webkit-outer-spin-button,
              &>input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }

          .mandatory {
            font-size: 13px;
            margin-top: 4px;
            color: #e5261c;
          }

          .missing {
            display: flex;

            // flex-wrap: wrap;
            &>div:nth-child(1) {
              margin-top: 5px;
            }

            gap: 5px;
            align-items: center;
            margin-top: 4px;

            .mandatory2 {
              margin-bottom: 6px;
              font-size: 13px;
              margin-top: 4px;
              color: #e5261c;
            }
          }

          .errorInput {
            border: 1px solid #e5261c;
            background-color: #e5261c14;
          }
        }
      }

      .fieldRadio {
        // display: flex;
        margin-top: 28px;

        &>label {
          color: #212121;
          font-family: "Poppins";
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 22.4px */
          margin-bottom: 8px;
        }

        &>div {
          display: flex;
          flex-wrap: wrap;
          gap: 32px 64px;
          margin-top: 21px;

          &>div {
            // min-width: 205px;
            display: flex;
            align-items: center;
            height: fit-content;
            gap: 8px;

            &>input {
              width: 21px;
              height: 21px;
            }

            input[type="radio"]:checked {
              background-color: red;
              border-color: red;
            }

            &>span {
              margin-top: 4px;
              color: #4f4f4f;
              font-family: "Poppins";
              font-size: 1rem;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
              /* 22.4px */
            }

            .info-icon {
              margin-top: -16px;
              margin-left: -6px;
              cursor: pointer;
              position: relative;
              display: inline-block;

              .tooltip-content {
                display: none;
                // display: block;
                position: absolute;
                z-index: 1;
                width: 293px;
                top: -70px;
                left: -12px;
                // transform: translateX(-50%);

                color: #000;
                font-family: "Poppins";
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 11px 12px;

                border-radius: 6px;
                border: 1px solid #e0e0e0;
                background: #fff;
                box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);
              }
            }

            .info-icon:hover .tooltip-content {
              display: block;
            }
          }
        }

        .mandatory {
          font-size: 136x;
          margin-top: 4px;
          color: #e5261c;
        }

        .missing {
          display: flex;
          gap: 5px;
          align-items: center;
          margin-top: 4px;
          font-size: 16px;

          .mandatory {
            margin-bottom: 6px;
          }
        }
      }

      .errorInput {
        border: 1px solid #e5261c;
        background-color: #e5261c14;
      }
    }

    .nextButton {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 50px;
      margin-top: 40px;
      width: 100%;
      min-width: 1000px;
      max-width: 1200px;
      padding: 0 42px;

      &>button {
        border-radius: 8px;
        background: #0B52D4;
        border: none;
        outline: none;
        padding: 13px 24px;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 22.4px */
        cursor: pointer;
      }
    }
  }
}

.dropDown-wrapper {
  width: 100%;
  position: relative;
  z-index: 10;

  .placeholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);
    gap: 0px;
    border: 1px solid #e0e0e0;
    background: #f8f9fb;

    .value {
      color: #4f4f4f;
      font-weight: 500;
      flex: 1;

      // height: 54px;
      &>input {
        // padding: 13px 16px;
        // padding-right: 0;
        background: inherit;
        outline: none;
        border: none;
        height: 100%;
        // width: calc(100% - 24px);
        width: 100%;
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 22.4px */
      }

      &>input::placeholder {
        color: #a7a7a7;
        font-weight: 400;
      }
    }

    .no-value {
      color: #a7a7a7;
      font-weight: 400;
    }

    .arrow {
      flex: 0;
      width: 24px;
      margin-bottom: -6px;
      // margin-right: 16px;
    }
  }

  .options-wrapper {
    box-shadow: 1px 4px 5px 5px rgb(0 0 0 / 4%),
      -2px -2px 4px 0px rgba(79, 79, 79, 0.04);
    position: absolute;
    width: 100%;
    background: #f8f9fb;
    color: #4f4f4f;
    font-weight: 500;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
    /* 22.4px */
    height: fit-content;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 2px;
    z-index: 100;

    &>div {
      padding: 13px 16px;
      border-bottom: 1px solid #dfdcdc;
      cursor: pointer;
    }

    &>div:nth-last-child(1) {
      border-bottom: none;
    }
  }

  .errorClass {
    border: 1px solid #e5261c;
    background-color: rgba(229, 38, 28, 0.0784313725);
  }

  .mandatory {
    position: absolute;
    font-size: 13px;
    margin-top: 4px;
    color: #e5261c;
    margin-bottom: 10px;
  }
}

.files {
  display: flex;
  gap: 26px;
  margin-top: 32px;

  .Jdmain {
    width: 407px;

    .resume {
      color: #212121;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 22.4px */
    }

    .dropBox {
      margin-top: 8px;
      height: 143px;
      border-radius: 8px;
      border: 1px dashed #e0e0e0;
      background: #fff;
      box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);
      display: flex;
      flex-direction: column;
      align-items: center;

      .uploadIcon {
        margin-top: 28px;

        &>img {
          width: 24px;
          height: 24px;
        }
      }

      .firstLabel {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 16px;

        &>label {
          &>span {
            color: #0B52D4;
            font-family: "Poppins";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .secondLabel {
        color: #afafaf;
        font-family: "Poppins";
        font-style: normal;
        margin-top: 8px;
        color: rgba(6, 43, 110, 0.6);
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
      }
    }

    .selected {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      height: fit-content;
      align-items: center;
      background: #fff;
      width: fit-content;
      margin-top: 14px;
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      justify-content: flex-start;

      &>div {
        padding: 13px 12px;

        &>img {
          cursor: pointer;
        }
      }

      .multiple {
        display: flex;
        gap: 15px;

        &>div {
          &>img {
            cursor: pointer;
          }
        }
      }
    }

    .selected-certificates {
      background: inherit;

      &>div {
        background: #fff;
        padding: 13px 12px;
      }
    }
  }
}

.FilesErrorMain {
  position: relative;
  padding: 20px;
  text-align: center;
  min-width: 387px;
  max-width: 650px;

  .close {
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
  }

  // .errorSign {
  // }
  .title1 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-top: 10px;
  }

  .title2 {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 4px;
  }

  .okay {
    width: 90px;
    height: 44px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    // background: #4318ff;
    background: #0B52D4;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    margin-top: 20px;
  }
}