body {
  margin: 0px !important;
  padding: 0;
  background: #f8f9fb;
}
.clickedItem {
  z-index: 11 !important;
}
label {
  font-family: 'Poppins';
}


