.client-signup-wrapper {


  .register-main {
    display: flex;
    /* Use flexbox layout */
    width: 100%;
    /* Full width of the viewport */
    height: 99vh;
    /* Full height of the viewport */
    margin: 0;
    padding: 0;

    .left-image {
      background: #FFFFFF3D;
      /* No change to the left image background */
      box-shadow: inset -3px 3px 4px 0px rgba(150, 150, 150, 0.08);
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      /* Vertically center content */
      justify-content: center;
      /* Horizontally center content */

      .item-content {
        max-width: 95%;
        width: 100%;
        display: flex;
        align-items: center;
        /* Center content */
        justify-content: center;
        /* Center content */
      }

      .logo-candidate {
        margin-top: 84px;
        width: 100%;
        max-width: 90%;

        &>img {
          width: 100%;
          height: auto;
          max-width: 80vh;
        }
      }
    }

    .right-container-wrapper {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      margin-top: 40px;
      /* Add top margin */
      margin-left: 60px;
      /* Add right margin */
      align-items: flex-start;

      .right-side-main {
        min-width: 450px;

        .form-main {
          .title {
            color: #212121;
            font-family: "Poppins";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 36px;

            margin-bottom: 30px;
          }

          label {
            display: block;
            color: #212121;
            font-family: "Poppins";
            margin-bottom: 8px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
          }

          input {
            border-radius: 8px;
            border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
            outline: none;
            color: #4f4f4f;
            font-family: "Poppins";
            padding: 13px 16px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
            display: block;
            width: 100%;
            max-width: 430px;
          }

          input::placeholder {
            color: #a7a7a7;
          }

          .privacy-consent {
            display: flex;
            gap: 8px;
            margin-top: 17px;
            justify-content: flex-start;
            align-items: center;

            &>input {
              width: fit-content;
              height: 24px;
              width: 24px;
              border: 1px solid #a7a7a7;
            }

            &>div {
              color: #4f4f4f;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              /* 19.6px */
            }
          }

          .last-btn {
            border-radius: 8px;
            background: #0B52D4;
            color: #fff;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            /* 22.4px */
            outline: none;
            border: none;
            margin-top: 30px;
            cursor: pointer;
          }

          .otpError {
            margin-top: 6px;
            color: #e5261c;
            font-family: "Poppins";
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 18.2px */
            margin-bottom: 30px;
          }
        }
      }
    }

    .right-side-main-2 {
      margin-top: 20px;

      .right-item {
        .title {
          color: #212121;
          font-family: "Poppins";
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .subtitle {
          color: #4f4f4f;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 10px;
          max-width: 481px;
        }

        .subtitle2 {
          width: 100%;

          &>span:nth-child(1) {
            font-weight: 600;
            line-height: 18px;
            margin: 0 4px;
          }

          &>span:nth-child(2) {
            color: #0b52d4;
            font-weight: 500;
            line-height: 140%;
            text-decoration-line: underline;
            cursor: pointer;
          }
        }

        .password-wrapper {
          width: 100%;
          max-width: 430px;
        }

        .label {
          color: #212121;
          font-family: "Poppins";
          font-size: 16px;
          margin-top: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 22.4px */
          display: block;
        }

        .content-input {
          outline: none;
          border-radius: 8px;
          border: 1px solid rgba(17, 17, 19, 0.2);
          width: 430px;
          padding: 9px 16px;
          margin-top: 8px;
          background: #fff;
          color: #000;
          font-size: 16px;
          font-weight: 500;
        }

        .content-input::placeholder {
          color: #a7a7a7;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        .otpError {
          margin-top: 6px;
          color: #e5261c;
          font-family: "Poppins";
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 18.2px */
        }

        .resendOtp {
          color: #888;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-top: 34px;

          &>span {
            color: #0b52d4;
            font-weight: 500;
            cursor: pointer;
          }
        }

        .last-button {
          max-width: 430px;
          width: 100%;
          padding: 13px 16px;
          outline: none;
          border: none;
          color: #fff;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          border-radius: 8px;
          background: #0B52D4;
          margin-top: 44px;
          cursor: pointer;
        }
      }
    }
  }
}