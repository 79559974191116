@media (min-width: 1400px) {
  .headerMain {
    width: 100%;
    box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 15;
    padding: 0px 40px;
    width: calc(100% - 80px);

    .header-container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 14px 0px;
      height: fit-content;
      align-items: center;

      .header-sub {
        display: flex;
        height: fit-content;
        align-items: center;
        gap: 40px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 1rem;
        color: #212121;

        &>div {
          padding-bottom: 8px;
          margin-top: 8px;
          cursor: pointer;
        }

        .logo {
          // width: 44px;
          // height: 44px;
          cursor: pointer;

          &>img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .services-dropdown {
          position: relative;
          display: flex;
          gap: 4px;
          align-items: center;
          margin-left: 60px;
          padding-bottom: 8px;
          border-bottom: 3px solid #fff;

          .main-dropDown {
            position: absolute;
            top: 100%;
            // left: -20px;
            display: flex;
            padding-top: 12px;

            .dropdown {
              flex-direction: column;
              background-color: #fff;
              // border: 1px solid #ccc;
              box-shadow: 2px 2px 4px 0px #4f4f4f14, -2px -2px 4px 0px #4f4f4f14;
              padding: 18px 14px;
              margin-top: 16px;

              &>div {
                cursor: pointer;
                margin-top: 24px;
              }

              &>div:hover {
                color: #0b52d4;
              }

              &>div:nth-child(1) {
                margin-top: 0px;
              }
            }
          }
        }

        .services-dropdown:hover {
          border-bottom: 3px solid #0b52d4;
          // border-radius: 2px;
          // background: #0B52D4;
        }
      }

      .header-sub2 {
        display: flex;
        gap: 20px;

        &>button {
          padding: 8px 22px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 19px;
          letter-spacing: 0.46px;
          border-radius: 5px;
          border: none;
          cursor: pointer;
        }

        &>button:nth-child(1) {
          background: #0B52D4;
          color: #fff;
        }

        &>button:nth-child(2) {
          background: #34a853;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .headerMain {
    width: 100%;
    box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 15;

    @media (max-width: 1340px) {
      padding: 0px 20px;
      width: calc(100% - 40px);
    }

    .header-container {
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 14px 0px;
      height: fit-content;
      align-items: center;

      .header-sub {
        display: flex;
        height: fit-content;
        align-items: center;
        gap: 40px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1rem;
        color: #212121;

        &>div {
          padding-bottom: 8px;
          margin-top: 8px;
          cursor: pointer;
        }

        .logo {
          // width: 44px;
          // height: 44px;
          cursor: pointer;

          &>img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .services-dropdown {
          position: relative;
          display: flex;
          gap: 4px;
          align-items: center;
          margin-left: 60px;
          padding-bottom: 8px;
          border-bottom: 3px solid #fff;

          .main-dropDown {
            position: absolute;
            top: 100%;
            // left: -20px;
            display: flex;
            padding-top: 12px;

            .dropdown {
              flex-direction: column;
              background-color: #fff;
              // border: 1px solid #ccc;
              box-shadow: 2px 2px 4px 0px #4f4f4f14, -2px -2px 4px 0px #4f4f4f14;
              padding: 18px 14px;
              margin-top: 16px;

              &>div {
                cursor: pointer;
                margin-top: 24px;
              }

              &>div:hover {
                color: #0b52d4;
              }

              &>div:nth-child(1) {
                margin-top: 0px;
              }
            }
          }
        }

        .services-dropdown:hover {
          border-bottom: 3px solid #4318ff;
        }
      }

      .header-sub2 {
        display: flex;
        gap: 20px;

        &>button {
          padding: 8px 22px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 19px;
          letter-spacing: 0.46px;
          border-radius: 5px;
          border: none;
          cursor: pointer;
        }

        &>button:nth-child(1) {
          background: #0B52D4;
          color: #fff;
        }

        &>button:nth-child(2) {
          background: #34a853;
          color: #fff;
        }
      }
    }
  }
}

@media (min-width: 650px) {
  .menu-logo {
    display: none;
  }
}

@media (max-width: 650px) {
  .headerMain {
    .header-container {
      padding: 15px 0px;

      .header-sub {
        width: 100%;
        justify-content: space-between;

        &>div {
          padding-bottom: 0px;
          margin-top: 0px;
          cursor: pointer;
        }

        .services-dropdown {
          display: none;
        }

        .contact-item {
          display: none;
        }

        .menu-logo {
          display: block;
        }
      }

      .header-sub2 {
        display: none;
      }
    }
  }
}

.mobile-menu-container {
  padding: 0 20px;
  font-family: "Poppins";
  position: fixed;
  z-index: 12;
  width: calc(100% - 40px);
  height: 100%;
  // margin: 0 auto;
  top: 72px;
  padding-top: 24px;
  // border: 1px solid;
  background: #fff;

  .services-dropdown {
    .titles {
      display: flex;
      justify-content: space-between;
      color: #212121;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.2px;
      border-bottom: 2px solid #fff;
    }

    .main-dropDown {
      .dropdown {
        color: #4f4f4f;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0 10px;

        &>div {
          margin-top: 16px;
        }
      }
    }
  }

  .contact-item {
    margin-top: 24px;
    font-weight: 500;
  }

  .header-sub2 {
    display: flex;
    gap: 20px;
    // justify-content: space-between;
    margin-top: 44px;

    &>button {
      padding: 8px 22px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.46px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }

    &>button:nth-child(1) {
      background: #0B52D4;
      color: #fff;
    }

    &>button:nth-child(2) {
      background: #34a853;
      color: #fff;
    }
  }
}