.upload-file-wrapper {
  position: relative;
  width: 399px;
  margin-top: 0px;

  .close {
    position: absolute;
    top: -6px; // 20px + 26px
    right: 20px;
    cursor: pointer;
  }

  .tick {
    text-align: center;
    margin-top: 26px;
  }

  .text1 {
    color: #212121;
    text-align: center;
    margin-top: 16px;
    margin-top: 16px;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .text2 {
    color: #212121;
    text-align: center;
    margin-top: 8px;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .okay {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 30px;

    &>button {
      border-radius: 8px;
      background: #0b52d4;
      padding: 12px 22px;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}