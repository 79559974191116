.new-vetting {
  width: 100%;
  // background: #f9f9f9;
  background: #fff;
  box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.1);

  @media (max-width: 1340px) {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .wrapper {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 60px 0px;

    .heading {
      font-family: "Poppins";
      font-size: 30px;
      font-weight: 600;
      line-height: 45px;
      letter-spacing: 0.2px;
      text-align: center;
    }

    .process {
      width: 100%;
      height: auto;
      margin-top: 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .process-mobile {
      display: none;
    }

    .main-button {
      width: fit-content;
      margin: 0 auto;
      margin-top: 50px;

      button {
        padding: 15px 40px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.46px;
        cursor: pointer;
        background: #0b52d4;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 8px;
      }
    }
  }
}

@media (max-width: 650px) {
  .new-vetting {
    @media (max-width: 1340px) {
      padding: 0 16px;
      width: calc(100% - 32px);
    }

    .wrapper {
      padding: 20px 0px;

      .heading {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .process {
        display: none;
      }

      .process-mobile {
        display: block;
        width: 100%;
        height: auto;
        margin-top: 26px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .main-button {
        margin-top: 30px;

        button {
          padding: 9px 24px;
          border-radius: 4px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.46px;
        }
      }
    }
  }
}